import React from "react";
import "../App.css";
import { Row, Col } from "react-bootstrap";

function Footer() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Footer" data-theme={theme}>
			<Row className="footer">
				<Col xs={12} md={6} lg={6} className="footer-left">
					<p className="copyright">
						<i className="fas fa-copyright"></i> Copyright 2020-2025
						- Sens Triathlon
					</p>
				</Col>
				<Col xs={12} md={6} lg={6} className="footer-right">
					<a href="/mentions" className="mentions">
						<p className="mentions">Mentions légales</p>
					</a>
				</Col>
			</Row>
		</div>
	);
}

export default Footer;
