import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Triathlon2025 from "./Triathlon2025";
//import BikeAndRun from "./BikeAndRun";
import Footer from "./Footer";

class Organisations extends Component {
	render() {
		return (
			<div className="Organisations">
				<Header />
				<Triathlon2025 />
				
				<Footer />
			</div>
		);
	}
}

export default Organisations;
