import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../App.css";
import AfficheTri from "../images/affichetri2025.jpg";
import ArnaudDepart from "../images/arnaud-depart.jpg";
// import AfficheBenevoles from "../images/affichebenevoles2023.jpg";
/*import VeloM from "../images/velo_m.png";
import VeloS from "../images/velo_s.png";
import RunM from "../images/run_m.png";
import RunS from "../images/run_s.png";
import VeloMgpx from "../documents/velo_m.gpx";
import VeloSgpx from "../documents/velo_s.gpx";
import RunMgpx from "../documents/run_m.gpx";
import RunSgpx from "../documents/run_s.gpx";
import NatationSM from '../images/natation_s_m.jpg'
import Programme from "../images/programme2022.jpg";
import Quai from "../images/quai.jpg";*/

/* import VeloPoPu from '../images/veloJ1_2024.png'
import VeloBeMi from '../images/veloJ2_2024.png'
import RunPoPu from '../images/capJ1_2024.png'
import RunBeMi from '../images/capJ2_2024.png'
import NatationPoPu from '../images/natationJ1_2024.png'
import NatationBeMi from '../images/natationJ2_2024.png'

import VeloS from '../images/veloS_2024.jpg'
import VeloM from '../images/veloM_2024.jpg'
import RunS from '../images/capS_2024.jpg'
import RunM from '../images/capM_2024.jpg'
import NatationS from '../images/natationS_2024.jpg'
import NatationM from '../images/natationM_2024.jpg' */

/* 
import Programme from "../images/programme2024.jpg"; */
import Kiwamis from "../images/kiwamis.jpg";
import Leaflet from "./Leaflet";
import CGV from "../documents/reglement.pdf";

class Triathlon2023 extends Component {
	render() {
		return (
			<div className="Triathlon2021" id="triathlon2021">
				<h1>Triathlon du Sénonais</h1>
				<hr />
{/* 				<Row>
				
					<Col xs={12} md={12} lg={6}>
						<img
							className="affiche-triathlon"
							alt="Affiche bénévole"
							src={AfficheBenevoles}
						/>
					</Col>
					<Col xs={12} md={12} lg={6}>
						<br />
						<h2>
							Nous recherchons des bénévoles pour l’organisation. Si vous êtes intéressés, merci de remplir le formulaire suivant.
						</h2>
						<h3 className="pink">
						<br />
						<br />
						<a 
						className="pink left"
						href="https://framaforms.org/benevoles-triathlon-du-senonais-2023-1645911408"
						target="_blank"
						rel="noopener noreferrer"
						>Lien vers le formulaire</a></h3>
						
					</Col>
				</Row> */}
				<Row>
					<Col xs={12} md={12} lg={6}>
						<img
							className="affiche-triathlon"
							alt="Affiche triathlon"
							src={AfficheTri}
						/>
					</Col>
					<Col xs={12} md={12} lg={6}>
						<br />
						{/* <h2>
							Après 8 éditions réussies autour de l’Etang de Gron,
							le triathlon du Sénonais a désormais lieu à Sens sur
							les quais de l’Yonne depuis 3 ans.
						</h2> */}
						<h2>
							La prochaine édition aura lieu le{" "}
							<strong>14 Juillet 2025</strong>, en ouverture des
							festivités de la fête nationale.
						</h2>
						<h2 className="pink">
							Cette année, on se retrouve de nouveau à l’Etang de Gron !
						</h2>
						<h2>
							4 épreuves sont prévues : Sprint (S), Olympique (M), Jeunes (de Poussins à Benjamins) et ...
						</h2>

						<h2 className="pink">
							Nouveauté cette année : une épreuve XS vient compléter le programme !
						</h2>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} lg={8}>
						<Leaflet />
					</Col>
					<Col xs={12} md={12} lg={4}>
						{/* <div
							class="fb-page"
							data-href="https://www.facebook.com/TriathlonduSenonais/"
							data-tabs="timeline"
							data-width="500"
							data-height="500"
							data-small-header="true"
							data-adapt-container-width="true"
							data-hide-cover="true"
							data-show-facepile="false"
						>
							<blockquote
								cite="https://www.facebook.com/TriathlonduSenonais/"
								class="fb-xfbml-parse-ignore"
							>
								<a href="https://www.facebook.com/TriathlonduSenonais/">
									Triathlon du Sénonais
								</a>
							</blockquote>
						</div> */}
						<img
							className="arnaud-depart"
							alt="Départ du triathlon 2024"
							src={ArnaudDepart}
						/>
					</Col>
				</Row>
				<div className="Space"></div>
				<h1 id="parcours">Parcours</h1>
				<hr />
				<h3>
							Bientôt disponible !
						</h3>
				{/* <Row>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left">Natation</h2>
						<div className="bluehr"></div>
						<h3 className="pink left">
							<a className="pink left" href={NatationPoPu} target='_blank' rel="noopener noreferrer">Parcours Poussins - Pupilles</a> <br /><br />
							<a className="pink left" href={NatationBeMi} target='_blank' rel="noopener noreferrer">Parcours Benjamins - Minimes</a> <br /><br />
							<a className="pink left" href={NatationS} target='_blank' rel="noopener noreferrer">Parcours S</a> <br /><br />
							<a className="pink left" href={NatationM} target='_blank' rel="noopener noreferrer">Parcours M</a> <br /><br />
							
						</h3>
					</Col>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left">Vélo</h2>
						<div className="bluehr"></div>
						<h3 className="pink left">
							
							<a className="pink left" href={VeloPoPu} target='_blank' rel="noopener noreferrer">Parcours Poussins - Pupilles</a> <br /><br />
							<a className="pink left" href={VeloBeMi} target='_blank' rel="noopener noreferrer">Parcours Benjamins - Minimes</a> <br /><br />
							<a className="pink left" href={VeloS} target='_blank' rel="noopener noreferrer">Parcours S</a> <br /><br />
							<a className="pink left" href={VeloM} target='_blank' rel="noopener noreferrer">Parcours M</a> <br /><br />

                			
						</h3>
					</Col>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left">Course à pied</h2>
						<div className="bluehr"></div>
						<h3 className="pink left">
						
							<a className="pink left" href={RunPoPu} target='_blank' rel="noopener noreferrer">Parcours Poussins - Pupilles</a> <br /><br />
							<a className="pink left" href={RunBeMi} target='_blank' rel="noopener noreferrer">Parcours Benjamins - Minimes</a> <br /><br />
							<a className="pink left" href={RunS} target='_blank' rel="noopener noreferrer">Parcours S</a> <br /><br />
							<a className="pink left" href={RunM} target='_blank' rel="noopener noreferrer">Parcours M</a> <br /><br />

						</h3>
					</Col>
				</Row> */}
				<div className="Space"></div>
				<h1 id="programme">Programme</h1>
				<hr />
				<h3>
							Bientôt disponible !
						</h3>
				{/* <img
					className="affiche-triathlon"
					alt="Programme"
					src={Programme}
				/> */}

				<div className="Space"></div>
				<h1 id="infos">Infos pratiques</h1>
				<hr />
				<Row>
					<Col xs={12} md={12} lg={6}>
						<h2 className="pink left" id="inscriptions">
							Inscriptions
						</h2>
						<div className="bluehr"></div>
						<h3 className="left">
							Vous pouvez vous inscrire dès maintenant !
						</h3>
						<a
							href="https://eventicom.fr/evenements/detail/13eme-Triathlon-du-Senonais-1425"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h3 className="left pink">
								Cliquez ici pour vous inscrire!
							</h3>
						</a>
					</Col>
					{/* <Col xs={12} md={12} lg={6}>
						<h2 className="pink left">Inscriptions groupées</h2>
						<div className="bluehr"></div>
						<h3 className="left">
							A partir de 10 inscrits d’un même club, vous
							bénéficiez d’un dossard offert.
						</h3>
					</Col> */}
				</Row>

				<Row>
					<Col xs={12} md={12} lg={6}>
						<h2 className="pink left" id="reglements">
							Règlement d'épreuve
						</h2>
						<div className="bluehr"></div>
						<h3 className="left">
							Cliquez sur le lien ci-dessous pour voir le
							règlement de l'épreuve !
							<br />
							<br />
							<a
								className="pink"
								href={CGV}
								target="_blank"
								rel="noopener noreferrer"
							>
								Règlement de l'épreuve
							</a>
						</h3>
					</Col>
					<Col xs={12} md={12} lg={6}>
						<h2 className="pink left">Autres réglementation</h2>
						<div className="bluehr"></div>
						<h3 className="left">
							Cliquez sur les liens ci-dessous pour voir les
							réglementations au format PDF!
							<br />
							<br />
							<a
								className="pink"
								href="https://drive.google.com/file/d/1Ax4sg0R8gT8YcQ3QRpggYvd8kC3089zX/view"
								target="_blank"
								rel="noopener noreferrer"
							>
								Réglementation FFTRI
							</a>
							<br />
							<br />
							<a
								className="pink"
								href="https://fftri.emiagic.io/regulation/pdf/rts"
								target="_blank"
								rel="noopener noreferrer"
							>
								Règles techniques et de sécurité
							</a>
						</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} lg={4}>
						
					</Col>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left center-title">Partenaires</h2>
						<div className="bluehr center-title"></div>
						
					</Col>
					<Col xs={12} md={12} lg={4}>
						
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} lg={4}>
						{/* <img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Sponsor2}
							
						/> */}
					</Col>
					<Col xs={12} md={12} lg={4}>
						
						<img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Kiwamis}
							
						/>
					</Col>
					<Col xs={12} md={12} lg={4}>
						{/* <img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Sponsor3}
							
						/>
						<p className="pink left center-title">
							Brasserie monclem
						</p> */}
					</Col>
				</Row>
			</div>
		);
	}
}

export default Triathlon2023;
