import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Triathlon2025 from "./Triathlon2025";
import Footer from "./Footer";

function Triathlon() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Triathlon" data-theme={theme}>
			<Header />
			<Triathlon2025 />
			<Footer />
		</div>
	);
}

export default Triathlon;
