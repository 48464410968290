import React from "react";
import "../App.css";
import {
	Nav,
	Navbar,
	NavDropdown,
	Dropdown,
	Container,
	Row,
	Col,
	Image,
} from "react-bootstrap";
import Logo from "../images/logo.png";
import useLocalStorage from "use-local-storage";
//import Benevole from "./Benevole";

function Header() {
	const defaultDark = window.matchMedia("(prefers-color-scheme: dark)")
		.matches;
	const [theme, setTheme] = useLocalStorage(
		"theme",
		defaultDark ? "dark" : "light"
	);

	const switchTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		setTheme(newTheme);
		localStorage.setItem("theme", { theme });
		window.location.reload();
	};

	return (
		<div>
			<div className="Header">
				<Container>
					<Row className="Banniere">
						<Col xs={12} md={7} lg={8} className="Logosection">
							<a href="/" rel="noopener noreferrer" alt="Accueil">
								<Image
									className="Logo"
									height="120"
									src={Logo}
									alt="Logo Sens Triathlon"
								/>
							</a>
						</Col>
						<Col xs={8} md={5} lg={4} className="Socialsection">
							<div className="SocialMedia">
								<a
									href="https://www.facebook.com/sens.triathlon"
									target="_blank"
									rel="noopener noreferrer"
									title="Facebook"
								>
									<i className="fab fa-facebook-square">
										{null}
									</i>
								</a>
								<a
									href="https://www.instagram.com/senstriathlon/"
									target="_blank"
									rel="noopener noreferrer"
									title="Instagram"
								>
									<i class="fab fa-instagram pink"></i>
								</a>
								<a
									href="mailto:senstriathlon89@gmail.com"
									target="_blank"
									rel="noopener noreferrer"
									title="Mail"
								>
									<i className="fas fa-envelope">{null}</i>
								</a>
								<a
									href="https://goo.gl/maps/Xww8QHeu4nLDhcyd9"
									target="_blank"
									rel="noopener noreferrer"
									title="Maps"
								>
									<i className="fas fa-map-marked-alt pink">
										{null}
									</i>
								</a>
								<a
									href="https://senstriathlon.yaentrainement.fr/"
									target="_blank"
									rel="noopener noreferrer"
									title="Yaentrainement"
								>
									<i className="fas fa-running">{null}</i>
								</a>
							</div>
						</Col>
						<Col xs={1} md={10} lg={8} className=""></Col>
						<Col xs={2} md={2} lg={4} className="">
							<div className="Nav">
								<Navbar
									expand="md"
									className="justify-content-end Navbar"
								>
									<Navbar.Toggle aria-controls="basic-navbar-nav Navbutton" />
									<Navbar.Collapse id="basic-navbar-nav">
										<Nav className=" ">
											<Nav.Link
												className="LinkNav"
												href="/"
											>
												Accueil
											</Nav.Link>
											<div className="Dropdown">
												<NavDropdown
													title="Club"
													className="nav-dropdown"
													id="nav-dropdown"
												>
													<NavDropdown.Item href="/club#licence">
														Se licencier
													</NavDropdown.Item>
													<NavDropdown.Item href="/club#bureau">
														Bureau
													</NavDropdown.Item>
													<NavDropdown.Item href="/club#projet">
														Projet associatif
													</NavDropdown.Item>
													<NavDropdown.Item href="/club#origines">
														Origines du club
													</NavDropdown.Item>
												</NavDropdown>
											</div>
											<div className="Dropdown">
												<NavDropdown
													title="Entrainements"
													className="nav-dropdown"
													id="nav-dropdown"
												>
													<NavDropdown.Item href="/entrainements#adultes">
														Entrainements adultes
													</NavDropdown.Item>
													<NavDropdown.Item href="/entrainements#ecole">
														Ecole de triathlon
													</NavDropdown.Item>
												</NavDropdown>
											</div>
											{/* <Nav.Link
												className="LinkNav"
												href="/dys"
											>
												Projet
											</Nav.Link> */}
											<div className="Dropdown">
												<NavDropdown
													title="Organisations"
													className="nav-dropdown"
													id="nav-dropdown"
												>
													<NavDropdown.Item href="/triathlon">
														Triathlon du Sénonais
													</NavDropdown.Item>
													<NavDropdown.Item href="/triathlon#programme">
														Programme
													</NavDropdown.Item>
													<NavDropdown.Item href="/triathlon#parcours">
														Parcours
													</NavDropdown.Item>
													<NavDropdown.Item href="/triathlon#inscriptions">
														Inscriptions
													</NavDropdown.Item>
													<NavDropdown.Item href="/triathlon#reglements">
														Règlements
													</NavDropdown.Item>
													{/* <NavDropdown.Item
														href="https://eventicom.fr/resultats_evenements/detail/1199"
														target="_blank"
													>
														Résultats
													</NavDropdown.Item> */}
													<Dropdown.Divider className="pink" />
													<NavDropdown.Item href="/bikeandrun">
														Bike&Run de Sens
													</NavDropdown.Item>
												</NavDropdown>
											</div>
											<Nav.Link
												className="LinkNav"
												href="/contact"
											>
												Contact
											</Nav.Link>
											<span className="spaceTheme"></span>
											<div className="inlineTheme">
												{localStorage.getItem(
													"theme"
												) === '"dark"' ? (
													<i className="far fa-moon"></i>
												) : (
													<i className="far fa-sun"></i>
												)}
												<label
													className="switch"
													title="Changement de thème"
												>
													<input
														onClick={switchTheme}
														className="SwitchTheme"
														type="checkbox"
													/>
													<span className="slider round"></span>
												</label>
												{localStorage.getItem(
													"theme"
												) === '"light"' ? (
													<i className="far fa-moon"></i>
												) : (
													<i className="far fa-sun"></i>
												)}
											</div>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			{/* <Benevole /> */}
		</div>
	);
}

export default Header;
